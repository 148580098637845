<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>
              <div class="col-lg-9">
                <div>
                  <h4 class="comm-title">Closing Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'VehicleDetails',
                                      params: {
                                        url_key: VehicleDetails.vehId,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  /></router-link>

                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                      :to="{
                                        name: 'VehicleDetails',
                                        params: {
                                          url_key: VehicleDetails.vehId,
                                        },
                                      }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                   
                                    <hr style="margin: 0px" />
                                    <div class="row">
                                      <div
                                        class="col-6"
                                        v-if="
                                          VehicleDetails.reAucStatus != true
                                        "
                                      >
                                        <div class="text-center">
                                          <button style="    background-color: #002868;"
                                            class="tile-bid-now"
                                            tabindex="-1"
                                            @click="
                                              getTopBidders(VehicleDetails)
                                            "
                                          >
                                            Resubmit
                                          </button>
                                        </div>
                                      </div>
                                      <div
                                        class="col-12"
                                        v-if="
                                          VehicleDetails.reAucStatus == true
                                        "
                                      >
                                        <router-link
                                          class="tile-bid-now" style="text-align:center;"
                                          tabindex="-1"
                                          :to="{
                                            name: 'VehicleDetails',
                                            params: {
                                              url_key: VehicleDetails.vehId,
                                            },
                                          }"
                                          >View Details</router-link
                                        >
                                      </div>
                                      <div
                                        class="col-6"
                                        v-if="
                                          VehicleDetails.reAucStatus != true
                                        "
                                      >
                                        <div class="text-center">
                                          <div>
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="
                                                ShowConfirmAlert(
                                                  VehicleDetails,
                                                  'Sale Confirmed'
                                                )
                                              "
                                            >
                                              Confirm Sale
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn auc-details"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>Auction Details</h4>
                  <div class="bod closing-details">
                    <table class="table">
                      <thead class="tbhead">
                        <tr>
                          <!-- <th>S.No</th> -->
                          <th>Name</th>
                          <th>Vin</th>
                          <th>Amount</th>
                          <th>Customer Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(bid, index) in bidLog" :key="index">
                          <!-- <td data-label="S.No"></td> -->
                          <td data-label="Name">{{ bid.auction }}</td>
                          <td data-label="Vin">{{ bid.vin }}</td>
                          <td data-label="Amount">{{ bid.bidAmount }}</td>
                          <td data-label="Customer Name">{{ bid.custName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <h6>Extended for</h6>
                  </div>
                  <div class="row">
                    
                    <div class="col-4">
                      <select class="form-control" v-model="aucEndHours">
                        <option
                          v-for="(hours, index) in aucEndhr"
                          :key="index"
                          :value="hours.hr"
                        >
                          {{ hours.hr }}
                        </option>
                      </select>
                      <span class="bodd-span">Hours</span>
                    </div>
                    <div class="col-4">
                      <select class="form-control" v-model="aucEndMin">
                        <option
                          v-for="(minutus, index) in aucEndmin"
                          :key="index"
                          :value="minutus.min"
                        >
                          {{ minutus.min }}
                        </option>
                      </select>
                      <span class="bodd-span">Min</span>
                    </div>
                   
                    <div class="col-4">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="showWarning(bidLog)"
                        >
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
               
                  
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
                <!-- confirm sale modal -->
                <div
                  
                  class="overlay-bid-warning warningStyle"
                  v-if="confirmModal && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(confirmModal = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>

                   <p>
                    Are you sure you want to add
                    <span
                      >{{ auctionEdit.brandName }} {{ auctionEdit.modelName }}
                      {{ auctionEdit.manYear }}</span
                    >
                    to {{ modelnameAuc }}? ! this action is irreversible
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="confirmModal = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="changeAucStatus(auctionEdit, modelnameAuc)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>


                <!-- reauction modal -->
                <div
                
                  class="overlay-bid-warning warningStyle"
                  v-if="openShowModel && userinfo"
                >
                  <button
                   
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openShowModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>
                   <p>
                    Are you sure you want to Start auction? Auction will be
                    available for top 3 bidders! This action is irreversible
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub" style="border:none"
                          @click="openShowModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub" style="border:none"
                          @click="startAuction(auctionEdit)"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>

import navbar from "@/components/navbar.vue";
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  components: {
    sideMenuVendor,
    PageNation,
    NotFound,
    navbar,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      openShowModel: false,
      confirmModal: false,
      auctionEdit: "",
      message: "",
      modelnameAuc: "",
      negotiationAmount: "",
      openNegotiationModel: false,
      customerNegAmount: null,
      auctionStatusMessage: "",
      modeNeg: "",
      noOfBidders: 3,
      bidLog: [],
      aucEndHours: "00",
      aucEndMin: "00",
      aucEndhr: [
        { hr: "00" },
        { hr: "01" },
        { hr: "02" },
        { hr: "03" },
        { hr: "04" },
        { hr: "05" },
        { hr: "06" },
        { hr: "07" },
        { hr: "08" },
        { hr: "09" },
        { hr: "10" },
        { hr: "11" },
        { hr: "12" },
        { hr: "13" },
        { hr: "14" },
        { hr: "15" },
        { hr: "16" },
        { hr: "17" },
        { hr: "18" },
        { hr: "19" },
        { hr: "20" },
        { hr: "21" },
        { hr: "22" },
        { hr: "23" },
      ],
      aucEndmin: [
        { min: "00" },
        { min: "01" },
        { min: "02" },
        { min: "03" },
        { min: "04" },
        { min: "05" },
        { min: "06" },
        { min: "07" },
        { min: "08" },
        { min: "09" },
        { min: "10" },
        { min: "11" },
        { min: "12" },
        { min: "13" },
        { min: "14" },
        { min: "15" },
        { min: "16" },
        { min: "17" },
        { min: "18" },
        { min: "19" },
        { min: "20" },
        { min: "21" },
        { min: "22" },
        { min: "23" },
        { min: "24" },
        { min: "25" },
        { min: "26" },
        { min: "27" },
        { min: "28" },
        { min: "29" },
        { min: "30" },
        { min: "31" },
        { min: "32" },
        { min: "33" },
        { min: "34" },
        { min: "35" },
        { min: "36" },
        { min: "37" },
        { min: "38" },
        { min: "39" },
        { min: "40" },
        { min: "41" },
        { min: "42" },
        { min: "43" },
        { min: "44" },
        { min: "45" },
        { min: "46" },
        { min: "47" },
        { min: "48" },
        { min: "49" },
        { min: "50" },
        { min: "51" },
        { min: "52" },
        { min: "53" },
        { min: "54" },
        { min: "55" },
        { min: "56" },
        { min: "57" },
        { min: "58" },
        { min: "59" },
      ],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId != "undefined") {
        this.getpendingSellerAuctions(this.userinfo.sellerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.customerNegAmount = 200000;
  },
  methods: {
    changeAucStatus(aucInfo, aucStatus) {
      this.updateAuctionStatus(aucInfo, aucStatus);
    },
    SubmitBid() {
      this.message = "Success";
      var self = this;
      setTimeout(function () {
        self.closeModel();
      }, 2000);
    },
    closeModel() {
      this.message = "";
      this.openShowModel = false;
      this.openModel = false;     
      setTimeout(() => {
        location.reload();
      }, 2000);
      
    },
    closeopenShowModel() {
      this.confirmModal = false;
      this.auctionStatusMessage = "";
      this.openShowModel = false;
      setTimeout(() => {
        location.reload();
      }, 2000);
      
    },
    ShowConfirmAlert(e, r) {
      this.modelnameAuc = r;
      this.auctionEdit = e;
      this.confirmModal = !this.confirmModal;
      this.openNegotiationModel = false;
      this.openModel = false;
    },

    ShowAlert(e, r) {
      this.modelnameAuc = r;
      this.auctionEdit = e;
      this.openShowModel = !this.openShowModel;
      this.openNegotiationModel = false;
      this.openModel = false;
    },
    showWarning(e) {
      if (e) {
        let aucInfo = "";
        for (let index = 0; index < e.length; index++) {
          const element = e[index];
          aucInfo = element;
        }

        aucInfo.hours = parseInt(this.aucEndHours);
        aucInfo.min = parseInt(this.aucEndMin);
        if (aucInfo.hours == 0 && aucInfo.min == 0) {
          this.message = "Please add extended time";
        } else {
          this.message = "";
          this.openShowModel = !this.openShowModel;
        }
      }
    },

    //auction resumbmitted
    startAuction() {
      let aucInfo = "";
      for (let index = 0; index < this.bidLog.length; index++) {
        const element = this.bidLog[index];
        aucInfo = element;
      }
      aucInfo.hours = parseInt(this.aucEndHours);
      aucInfo.min = parseInt(this.aucEndMin);
    
        userService
          .extentAuction(aucInfo, this.userinfo.sellerId)
          .then((response) => {
            this.message = response.data.Message;
            console.log(response);

            if (this.message == "Success") {
              this.message ="";
              var self = this;
              setTimeout(function () {
                self.closeModel();
              }, 2000);

              this.$toast.success("Auction created successfully", {
              position: "top",
              duration: 3000,
            });

            }
          })
          .catch((error) => {
            console.log(error);
             this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
          });
      
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    getTopBidders(e) {
      this.openModel = !this.openModel;
      this.openShowModel = false;
      let aucid = e.aucid;
      userService
        .getReAuctionLog(aucid)
        .then((response) => {
          let resmessage = response.data.Message;
          if (resmessage == "Success") {
            this.bidLog = response.data.Data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateAuctionStatus(auctionDetails, aucStatus) {
      //  console.log(auctionDetails,aucStatus);
      userService
        .updAuctionStatus(auctionDetails, aucStatus)
        .then((response) => {
          this.auctionStatusMessage = response.data.Message;
          if (this.auctionStatusMessage == "Success") {
            this.auctionStatusMessage = "";
            this.$toast.success("Success", {
              position: "top",
              duration: 3000,
            });
          
              this.closeopenShowModel();
           
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
        });
    },
    confirmSale(auctionEdit){
      // updateAuctionStatus
      this.updateAuctionStatus(auctionEdit, "Sale Confirmed");
    },


    addNegotiation(negotiationDetail, offerAmount) {
      if (this.modeNeg == "Sale Confirmed") {
        this.updateAuctionStatus(negotiationDetail, this.modeNeg);
      } else if (this.modeNeg == "Canceled") {
        this.updateAuctionStatus(negotiationDetail, this.modeNeg);
      } else if (this.modeNeg == "Negotiate") {
        userService
          .insNegotiationDetails(negotiationDetail, offerAmount)
          .then((response) => {
            this.auctionStatusMessage = response.data.Message;
            if (this.auctionStatusMessage == "Success") {
              var self = this;
              setTimeout(function () {
                self.closeopenShowModel();
              }, 2000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getpendingSellerAuctions(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getSellerAuctionsStatus(id, "Closing")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
           // console.log(this.AuctionsDetails);
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
            this.vechApproval = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 700px){



  .auc-details{
    position: fixed;
    z-index: 9999;
    top: -18%!important;
    left: 15px!important;
    padding: 20px 20px!important;
    background-color: rgb(229 245 255);
    position: fixed;
    width: 90%!important;
    margin: 149px 0%!important;
    border-radius: 5px;
    
  }
  .bod {
    margin: 0px !important;
    padding: 0px!important;
   
}
.table td {
    text-align: right;
    padding-left: 35%!important;
    text-align: right;
    position: relative;
}
	.overlay-bid-warning {
    position: fixed;
    z-index: 9999;
    top: 0%!important;
    left: 15px!important;
    padding: 20px 20px!important;
    background-color: rgb(226 240 248);
    position: fixed;
    width: 90%!important;
    margin: 150px 0%!important;
    border-radius: 5px;
}
}



.overlay-bid-btn {
  position: fixed;
    z-index: 9999;
    top: 0%;
    left: -8%;
    padding: 20px 30px;
    background-color: rgb(255 255 255);
    position: fixed;
    width: 50%;
    margin: 150px 35%;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 4px #00000012;
}
.overlay-bid-warning {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(226 240 248);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px;
}

.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.bod {
  margin: 0;
    padding: 12px 0px;
}

* {
  box-sizing: border-box;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

td th {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

th {
  background-color: #648ed1;
  color: #ffffff;
}

tbody {
  background-color: #f5f5f5;
}

/*responsive*/

@media (max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 15px;
  }
  .table td {
    text-align: right;
    padding-left: 50%;
    text-align: right;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}
</style>
